import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

import LandlordProtectedRoute from 'src/components/auth/LandlordProtectedRoute';

const PropertiesPage = lazy(() => import('src/pages/landlord/properties/PropertiesPage'));
const ConversationsPage = lazy(() => import('src/pages/landlord/conversations/ConversationsPage'));
const LeadsPage = lazy(() => import('src/pages/landlord/leads/LeadsPage'));
const LeadMessagesPage = lazy(() => import('src/pages/landlord/leads/messages/LeadMessagesPage'));
const LandlordSettings = lazy(() => import('src/pages/landlord/account/LandlordSettings'));
const PlaybooksPage = lazy(() => import('src/pages/landlord/playbooks/index/PlaybooksPage'));
const NewPlaybookPage = lazy(() => import('src/pages/landlord/playbooks/new/NewPlaybookPage'));
const NewPlaybookPageV2 = lazy(() => import('src/pages/landlord/playbooks-v2/new/NewPlaybookPage'));
const PlaybooksEditPage = lazy(() => import('src/pages/landlord/playbooks/edit/PlaybooksEditPage'));
const EditPlaybookPageV2 = lazy(() => import('src/pages/landlord/playbooks-v2/edit/EditPlaybookPage'));
const MigrateTeamPlaybooksPage = lazy(() => import('src/pages/landlord/migrate-team-playbooks/MigrateTeamPlaybooksPage'));
const ShowingsPage = lazy(() => import('src/pages/landlord/showings/ShowingsPage'));
const AnalyticsPage = lazy(() => import('src/pages/landlord/analytics/AnalyticsPage'));
const LandlordTeam = lazy(() => import('src/pages/landlord/team/LandlordTeam'));
const UnmatchedListingsPage = lazy(() => import('src/pages/landlord/unmatched-listings/UnmatchedListingsPage'));

/**
 * Routes for the landlord protected pages.
 * They have to be nested under the `/landlord/inner` route.
 */
export const landlordProtectedRoutes = [
    <Route key="analytics" path="analytics" element={<LandlordProtectedRoute><AnalyticsPage /></LandlordProtectedRoute>} />,
    <Route key="playbooks" path="playbooks" element={<LandlordProtectedRoute><PlaybooksPage /></LandlordProtectedRoute>} />,
    <Route key="playbooks/new" path="playbooks/new" element={<LandlordProtectedRoute><NewPlaybookPage /></LandlordProtectedRoute>} />,
    <Route key="playbooks/:id" path="playbooks/:id" element={<LandlordProtectedRoute><PlaybooksEditPage /></LandlordProtectedRoute>} />,
    <Route key="playbooks-v2/new" path="playbooks-v2/new" element={<LandlordProtectedRoute><NewPlaybookPageV2 /></LandlordProtectedRoute>} />,
    <Route key="playbooks-v2/:id" path="playbooks-v2/:id" element={<LandlordProtectedRoute><EditPlaybookPageV2 /></LandlordProtectedRoute>} />,
    <Route key="migrate-team-playbooks" path="migrate-team-playbooks" element={<LandlordProtectedRoute><MigrateTeamPlaybooksPage /></LandlordProtectedRoute>} />,
    <Route key="properties" path="properties" element={<LandlordProtectedRoute><PropertiesPage /></LandlordProtectedRoute>} />,
    <Route key="messages" path="messages" element={<LandlordProtectedRoute><ConversationsPage /></LandlordProtectedRoute>} />,
    <Route key="messages/from-lead" path="messages/from-lead" element={<LandlordProtectedRoute><LeadMessagesPage /></LandlordProtectedRoute>} />,
    <Route key="showings" path="showings" element={<LandlordProtectedRoute><ShowingsPage /></LandlordProtectedRoute>} />,
    <Route key="settings" path="settings" element={<LandlordProtectedRoute><LandlordSettings /></LandlordProtectedRoute>} />,
    <Route key="leads/all" path="leads/all" element={<LandlordProtectedRoute><LeadsPage /></LandlordProtectedRoute>} />,
    <Route key="team" path="team" element={<LandlordProtectedRoute><LandlordTeam /></LandlordProtectedRoute>} />,
    <Route key="unmatched-listings" path="unmatched-listings" element={<LandlordProtectedRoute><UnmatchedListingsPage /></LandlordProtectedRoute>} />,
];
import type { Dayjs } from 'dayjs';

export enum TimeWindow {
  LAST_WEEK = 'last_week',
  CURRENT_WEEK = 'current_week',
  TWO_WEEKS_AGO = 'two_weeks_ago',
  THREE_WEEKS_AGO = 'three_weeks_ago'
}

export enum EAnalyticsPageTab {
  Leads = 'leads',
  Conversations = 'conversations',
  Tours = 'tours',
  Applications = 'applications',
  Properties = 'properties',
  Performance = 'performance'
}

export const analyticsPageTabLabels = {
    [EAnalyticsPageTab.Leads]: 'Leads',
    [EAnalyticsPageTab.Conversations]: 'Conversations',
    [EAnalyticsPageTab.Tours]: 'Tours',
    [EAnalyticsPageTab.Applications]: 'Applications',
    [EAnalyticsPageTab.Properties]: 'Properties',
    [EAnalyticsPageTab.Performance]: 'Team Performance'
};

export type DateRange = {
    from: Dayjs,
    to: Dayjs
}

export enum EDateRange {
  CurrentWeek = 'CURRENT_WEEK',
  LastWeek = 'LAST_WEEK',
  TwoWeeksAgo = 'TWO_WEEKS_AGO',
  ThreeWeeksAgo = 'THREE_WEEKS_AGO',
}
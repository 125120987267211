import type { ILabel, LabelType } from 'src/services/api/labels';

export enum LeadStage {
  NOT_SET = 'not_set',
  NEW = 'new',
  CONTACTED = 'contacted',
  TOUR_SCHEDULED = 'tour_scheduled',
  TOUR_COMPLETED = 'tour_completed',
  INVITED_TO_APPLY = 'invited_to_apply',
  APPLICATION_COMPLETED = 'application_completed',
  APPLICATION_APPROVED = 'application_approved',
  NOT_QUALIFIED = 'not_qualified',
}

export const LEAD_STAGES = Object.values(LeadStage);
/**
* The stages that are selectable by the user.
* This is all stages except for `NOT_SET`.
*/
export const SELECTABLE_LEAD_STAGES = LEAD_STAGES.filter((o) => o !== LeadStage.NOT_SET);

export type ILeadLabel = ILabel & {
  type: LabelType.LEAD;
}

export interface ILeadNotes {
  id: number;
  lead_uuid: string;
  leasing_team_id: number;

  contents: string;

  created_at: string;
  modified_at: string;
}

export type ILead = {
  uuid: string;
  owner: number;
  leasing_team_id: number;
  property_id: number | null;
  facebook_user_id: string | null;

  name: string | null;
  email: string | null;
  phone_number: string | null;

  /**
   * How the lead came to be
   * e.g. csv-file, listing-site-email, onboarding-import, etc.
   */
  ingestion_method: string;
  /**
   * Where the landlord got the lead (Zumper, Yardi, etc.)
   */
  ingestion_source: string;

  /**
   * Whether this is a lead made specifically for testing by this user
   * Usually will match their own email and phone number
   */
  is_synthetic: boolean;
  is_archived?: boolean;
  has_upcoming_showing: boolean;

  stage: LeadStage;
  labels: ILeadLabel[];

  notes: ILeadNotes | null;
  /**
   * This is a pile of stuff we are mostly extracting from listing emails
   */
  smart_fields_data?: Record<string, string | Record<string, string>>;

  // Example: Thu, 21 Nov 2024 05:00:24 GMT
  inserted_at: string;
  modified_at: string;
}

export type ICreateLeadRequest = {
  name: string;
  email: string | null;
  phone_number?: string | null;

  ingestion_source: string;

  // A thorn in my eye
  // This should be `property_id` and it should store the ID, not the name
  // This whole request type is a mess
  /**
   * The name of the property.
   * Omit if not associated with a property.
   */
  property?: string;

  is_synthetic?: boolean;

  labels?: ILeadLabel[];
}
import type { MaintenanceWindowNotification } from 'src/common/maintenance-window';

export enum ENotificationType {
    FailedToMatchListingToProperty = 'failed_to_match_listing_to_property',
    FailedFacebookIntegration = 'facebook_integration_error',
    UpcomingMaintenanceWindow = 'upcoming_maintenance_window',
    NonLeasedProperty = 'non_leased_property',
}

export type NotificationBase = {
    id: number;
    text: string;
    type: ENotificationType;
    is_read_by_user: boolean;
    is_informational: boolean;
    is_shared_with_team: boolean;
    inserted_at: string;
    modified_at: string;
};

export type UnmatchedListingNotification = NotificationBase & {
    payload: {
        name: string;
        ingestion_source: string;
        ingestion_method: string;
        leasing_team_id: number;
        num_leads: number;
        image_url?: string;
    }
};

export type NonLeasedPropertyNotification = NotificationBase & {
    payload: {
        property_id: number;
        property_name: string;
    }
};

export type Notification =
    | UnmatchedListingNotification
    | MaintenanceWindowNotification
    | NonLeasedPropertyNotification;

export const isUnmatchedListingNotification = (n: Notification): n is UnmatchedListingNotification => {
    return n.type === ENotificationType.FailedToMatchListingToProperty;
};

export const isFacebookIntegrationNotification = (n: Notification): boolean => {
    return n.type == ENotificationType.FailedFacebookIntegration;
};

export const isUpcomingMaintenanceWindowNotification = (n: Notification): n is MaintenanceWindowNotification => {
    return n.type == ENotificationType.UpcomingMaintenanceWindow;
};

export const isNonLeasedPropertyNotification = (n: Notification): n is NonLeasedPropertyNotification => {
    return n.type == ENotificationType.NonLeasedProperty;
};

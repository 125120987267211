export const GET_LEADS_PATH = 'api/landlord/leads';

export const LEAD_INGESTION_METHOD_OPTIONS = [
    { value: 'onboarding-import', label: 'Onboarding Import' },
    { value: 'zillow-webhook', label: 'Zillow Webhook' },
    { value: 'csv-file', label: 'CSV File' },
    { value: 'manual', label: 'Manual' },
    { value: 'scheduled-resman-email-csv-report', label: 'Scheduled Resman Email CSV Report' },
    { value: 'inbound-email', label: 'Inbound Email' },
    { value: 'listing-site-email', label: 'Listing Site Email' },
    { value: 'inbound-sms', label: 'Inbound SMS' },
    { value: 'fb_messenger', label: 'FB Messenger' },
];

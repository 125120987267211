
import { useQuery } from 'react-query';

import type { Notification } from 'src/services/api/notifications/types';
import { Config } from 'src/config';
import { apiClient } from 'src/services/api';
import { queryKeys } from 'src/services/api/constants';

const PATH = 'api/landlord/notifications';

type Response = {
    notifications: Notification[];
};

export const useNotifications = (disabled?: boolean) => {
    return useQuery(
        [queryKeys.notifications],
        async () => {
            const response = await apiClient(PATH).json<Response>();
            const serverNotifications = response.notifications;

            const localNotifications: Notification[] = [];
            if (Config.maintenanceWindow?.isUpcoming()) {
                localNotifications.push(Config.maintenanceWindow.toNotification());
            }

            return serverNotifications.concat(localNotifications);
        }, {
            enabled: !disabled,
        }
    );
};

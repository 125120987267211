const formatSource = (source: string) => {
    let formattedSource: string;

    if (source === 'zillowrentalnetwork') {
        formattedSource = 'Zillow Rental Network';
    } else if (source === 'zillowrentals') {
        formattedSource = 'Zillow Rentals';
    } else if (
        source.includes('zillow') ||
        source.includes('hotpads') ||
        source.includes('trulia')
    ) {
        formattedSource = 'Zillow Brands';
    } else if (source.includes('apartmentlist')) {
        formattedSource = 'Apartment List';
    } else if (
        source.includes('apartmentscom') ||
        source.includes('forrent') ||
        source.includes('apartmentfinder') ||
        source.includes('apartamentos') ||
        source.includes('apartmenthomeliving') ||
        source.includes('forrentuniversity') ||
        source.includes('after') ||
        source.includes('corporatehousing') ||
        source.includes('westsiderentals')
    ) {
        formattedSource = 'Apartments.com Brands';
    } else if (source.includes('craigslist')) {
        formattedSource = 'Craigslist';
    } else if (source.includes('rentpath')) {
        formattedSource = 'RentPath';
    } else if (source.includes('realtorcom')) {
        formattedSource = 'Realtor.com';
    } else if (source.includes('redfin')) {
        formattedSource = 'Redfin';
    } else if (source.includes('website')) {
        formattedSource = 'Website';
    } else if (source.includes('streeteasy')) {
        formattedSource = 'StreetEasy';
    } else if (source.includes('zumper')) {
        formattedSource = 'Zumper';
    } else if (source.includes('rentlinx')) {
        formattedSource = 'RentLinx';
    } else if (source.includes('rentcafe')) {
        formattedSource = 'RentCafe';
    } else if (source.includes('affordablehousing')) {
        formattedSource = 'AffordableHousing';
    } else if (
        source.includes('fbmessenger') ||
        source.includes('facebook')
    ) {
        formattedSource = 'Facebook';
    } else if (source.includes('tenantturner')) {
        formattedSource = 'Tenant Turner';
    } else if (source.includes('reffieinboundsms')) {
        formattedSource = 'Reffie Inbound SMS';
    } else if (source.includes('priorresident')){
        formattedSource = 'Prior Resident';
    } else {
        formattedSource = source.charAt(0).toUpperCase() + source.slice(1);
    }
    return formattedSource;
};

export const normalizeSource = (source: string) => {
    const normalizedSource = source.match(/[a-zA-Z]/g)?.join('').toLowerCase() || '';
    return formatSource(normalizedSource);
};

export const getSourcesFromNormalizedSource = (source: string) => {
    switch (source) {
        case 'Zillow Rental Network':
            return ['zillowrentalnetwork'];
        case 'Zillow Rentals':
            return ['zillowrentals'];
        case 'Zillow Brands':
            return ['zillow', 'hotpads', 'trulia'];
        case 'Apartment List':
            return ['apartmentlist'];
        case 'Apartments.com Brands':
            return [
                'apartmentscom',
                'forrent',
                'apartmentfinder',
                'apartamentos',
                'apartmenthomeliving',
                'forrentuniversity',
                'after',
                'corporatehousing',
                'westsiderentals',
            ];
        case 'Craigslist':
            return ['craigslist'];
        case 'RentPath':
            return ['rentpath'];
        case 'Realtor.com':
            return ['realtorcom'];
        case 'Redfin':
            return ['redfin'];
        case 'Website':
            return ['website'];
        case 'StreetEasy':
            return ['streeteasy'];
        case 'Zumper':
            return ['zumper'];
        case 'RentLinx':
            return ['rentlinx'];
        case 'RentCafe':
            return ['rentcafe'];
        case 'AffordableHousing':
            return ['affordablehousing'];
        case 'Facebook':
            return ['fbmessenger', 'facebook'];
        case 'Tenant Turner':
            return ['tenantturner'];
        case 'Reffie Inbound SMS':
            return ['reffieinboundsms'];
        case 'Prior Resident':
            return ['priorresident'];
        default:
            return [source];
    }
};
